import React from 'react'
import Boostarp from './Boostarp'
import Div from './Div'
import Footer from './Footer'
import Header from './Header'
import Nav from './Nav'
import Section from './Section'

const App = () => {
    return (
        <div>
            <Nav/>
           
            <Section/>
            <Div/>
            <Footer/>
            <Boostarp/>
        
            
        </div>
    )
}

export default App
